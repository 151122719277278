<template>
    <v-dialog
        v-if="visible"
        v-model="visible"
        transition="dialog-top-transition"
        scrollable
        persistent
        max-width="500"
    >
        <v-card class="modal-maincard">

            <v-toolbar dark flat dense>
                <v-toolbar-title>{{ $t(title) }}</v-toolbar-title>
            </v-toolbar>

            <v-card-text class="wrapperFormModal">

                <div class="onlyReadData">
                    {{ $t('Срок_действия_ключа_истекает.message', { date: certificateEndDate, days: certificateDaysRemain } ) }}
                </div>

            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn 
                    color="cyan"
                    text
                    depressed
                    @click="ok"
                >
                    {{ $t("Ок") }} 
                </v-btn>

            </v-card-actions>

        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: "CertificateExpiresDlg",
    data () {
        return {
            title: "Срок_действия_ключа",

            options: {
                color: 'grey lighten-3',
                width: 400,
                zIndex: 200,
                noconfirm: false,
            }
        }
    },
    computed: {
        ...mapGetters('global/dialogs/certificateExpires',
        { 
            visible: 'isVisible',
            certificateEndDate: 'getCertificateEndDate',
            certificateDaysRemain: 'getCertificateDaysRemain'
        }),
    },
    methods: {
        ...mapActions('global/dialogs/certificateExpires', ['ok'])
    }
}
</script>